import React from 'react';

import styles from './NavigationDropdownContent.module.css';
import { ListLinks } from './ListLinks';
import { Promotion } from './Promotion';
import { Tabs } from './Navigation';

interface Props {
  tabData: Tabs;
  active: boolean;
  uriLocationReference: string;
  onSubNavLinkClick: (
    label: string,
    index: number,
    redirectUri: string
  ) => void;
}
export function NavigationDropdownContent(props: Props): React.ReactElement {
  function generatePromotions(): React.ReactNode {
    if (!props.tabData.promos) {
      return null;
    }

    return props.tabData.promos.map((promo, index) => (
      <Promotion
        key={index}
        index={index + 100 /* tracking requirement */}
        promo={promo}
        active={props.active}
        category={props.tabData.label}
        uriLocationReference={props.uriLocationReference}
        onClick={props.onSubNavLinkClick}
      />
    ));
  }

  const promotions = generatePromotions();

  if (!props.active) {
    return <div className={styles.nonactive} />;
  }
  return (
    <div className={styles.active}>
      <div className={styles.links}>
        <ListLinks
          items={props.tabData['child-items']}
          uriLocationReference={props.uriLocationReference}
          onLinkClick={props.onSubNavLinkClick}
        />
      </div>
      <div className={styles.promotions}> {promotions}</div>
    </div>
  );
}
