import React from 'react';
import {
  MarketplaceItem,
  MarketplaceOutput,
} from 'js/model/rainbow/content/MarketplaceOutput';
import styles from './MarketplacesList.module.css';

function generateList(
  marketplaces: (MarketplaceItem | null)[],
  currentChannelCode: string
): React.ReactNode {
  // since the user is already on one of the marketplaces, the number
  // in the dropdown is one fewer than the total number of marketplaces.
  const countDropdownMarketplaces = marketplaces.length - 1;
  const splitIndex = Math.floor(marketplaces.length / 2 - 1);

  // Adds an empty list item so the right column of the country
  // lists has more results.
  const marketplacesWithEmptySlot =
    countDropdownMarketplaces % 2 !== 0
      ? marketplaces
          .slice(0, splitIndex)
          .concat([null])
          .concat(marketplaces.slice(splitIndex))
      : marketplaces;

  return marketplacesWithEmptySlot.map(item => {
    const channelCode = item ? item.channelCode : null;

    if (channelCode) {
      if (channelCode === currentChannelCode) {
        return null;
      }
      return (
        <li key={channelCode} className={styles.item}>
          <a href={item?.uri.desktopUri} className={styles.link}>
            {item?.countryName}
          </a>
        </li>
      );
    }
    return (
      <li key="dummy" className={styles.item}>
        &nbsp;
      </li>
    );
  });
}

interface Props {
  marketplaces: MarketplaceOutput;
  currentChannelCode: string;
}

export const MarketplacesList = ({
  marketplaces,
  currentChannelCode,
}: Props) => {
  return (
    <ul className={styles.list}>
      {generateList(marketplaces, currentChannelCode)}
    </ul>
  );
};
