import { SDKEventAttrs } from '@mparticle/web-sdk';
import { mParticleEventType, mParticleLogEvent } from '@treatwell/ui';

export const trackEvent = ({
  eventName,
  eventType,
  eventAttributes,
}: {
  eventName: string;
  eventType?: typeof mParticleEventType[keyof typeof mParticleEventType];
  eventAttributes: SDKEventAttrs;
}) => {
  mParticleLogEvent(
    eventName,
    eventType || mParticleEventType.Other,
    eventAttributes
  );
};
